<template>
  <v-card flat>
    <toolbar :title="$t('user.ac')">
      <v-btn small text color="success" class="mx-2" @click="save">
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </toolbar>

    <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(104)">
      <v-form ref="form" class="py-5 px-5">
        <v-list dense>
          <v-list-item-group color="primary">
            <div v-for="(r, i) in getRoles" :key="i">
              <v-divider v-if="i !== 0" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">
                    {{ r.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ r.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    v-model="selectedRoles"
                    :value="r.name"
                  />
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
        <br />
        <v-divider />
        <v-btn
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn color="primary" tile depressed @click="reset">
          <v-icon>mdi-undo-variant</v-icon>
          {{ $t("general.undo") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "UserRoles",
  components: { Toolbar },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          roles: [],
        };
      },
    },
  },
  watch: {
    item: {
      handler: function(n) {
        if (n) {
          this.selectedRoles = n.roles;
        }
      },
    },
  },
  data() {
    return {
      selectedRoles: [],
      originalState: { id: 0 },
    };
  },
  mounted() {
    Object.assign(this.originalState, this.item);
  },
  computed: {
    ...mapGetters("roles", ["getRoles"]),

    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        id: this.idParam,
        roles: this.selectedRoles,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
