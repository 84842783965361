<template>
  <collapsable-card :title="title" flat>
    <div align="center">
      <v-avatar
        size="126"
        color="grey lighten-3"
        v-if="avatar"
        style="border: 3px solid #44444430;"
      >
        <img :src="!!tempImage ? tempImage : getImage" />
      </v-avatar>
      <v-card v-if="!avatar" height="64" color="grey lighten-3" flat style="overflow: hidden;">
        <img
          height="64"
          contain
          :src="!!tempImage ? tempImage : getImage"
          onerror="this.style.display='none'"
        />
      </v-card>
    </div>
    <v-file-input
      v-model="imageModel"
      @change="imageChanged"
      @click:clear="clearImage"
      accept="image/png, image/jpeg, image/bmp"
      placeholder="Pick an image"
      append-icon="mdi-camera"
      :label="$t('general.imageFile')"
      :disabled="!editMode"
      show-size
      clearable
      clear-icon="mdi-image-off"
    ></v-file-input>

    <v-alert
      v-if="!editMode"
      border="left"
      colored-border
      elevation="2"
      type="info"
      dense
      class="body-2"
    >
      You can update the image after saving the entity
    </v-alert>
    <v-divider class="my-3" />

    <v-btn
      :disabled="!imageModel && !editMode"
      depressed
      @click="uploadImage"
    >
      <v-icon>mdi-progress-upload</v-icon>
      Upload
    </v-btn>
  </collapsable-card>
</template>

<script>
import CollapsableCard from "./CollapsableCard";
export default {
  name: "ImageUpoader",
  components: { CollapsableCard },
  props: {
    editMode: Boolean,
    disabled: Boolean,
    title: String,
    dir: String,
    keyAttr: String,
    path: String,
    avatar: Boolean,
  },
  data() {
    return {
      imageModel: null,
      tempImage: null,
    };
  },
  computed: {
    getImage() {
      return `${this.getApiBaseUrl}/files/image/?path=${this.dir ? this.dir : this.avatar ? "avatars" : ""}/${
        this.keyAttr ? "_" + this.keyAttr + ".png" : this.path ? this.path : "default"}`;
    },
  },
  methods: {
    imageChanged(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.tempImage = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    clearImage() {
      this.imageModel = null;
      this.tempImage = null;
    },
    uploadImage() {
      if (this.imageModel) {
        this.$upload(
          `/files/image/?path=${this.dir ? this.dir : this.avatar ? "avatars" : ""}/${this.keyAttr ? this.keyAttr + ".png" : this.path}`,
          this.imageModel
        )
          .then(() =>
            this.showFeedback({
              text: "messages.updateDone",
              color: "success",
            })
          )
          .catch(() =>
            this.showFeedback({
              text: "messages.updateNotSuccessful",
              color: "grey",
            })
          );
      }
    },
  },
};
</script>
