<template>
  <view-container breadcrumbs>
    <toolbar :title="$t('user.ac')">
      <v-btn small text color="success" class="mx-2" @click="save">
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </toolbar>

    <v-card flat>
      <v-card-text
        class="overflow-y-auto px-0 py-0"
        :style="getViewHeightStyle(84)"
      >
        <v-list dense>
          <v-list-item-group color="primary">
            <div v-for="(store, i) in getStores" :key="i">
              <v-divider v-if="i !== 0" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">
                    {{ store.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ store.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="selectedStores" :value="store.id" />
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>

        <form-bar disable-validation @save="save" @reset="reset" />
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";
import FormBar from "@/common/components/FormBar";

export default {
  name: "UserStores",
  components: { ViewContainer, Toolbar, FormBar },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          stores: [],
        };
      },
    },
  },
  data() {
    return {
      selectedStores: [],
      originalState: { id: 0 },
    };
  },
  mounted() {
    if (this.$getUserInfo().assignedStoreIds) {
      this.selectedStores = this.$getUserInfo().assignedStoreIds;
    }
    Object.assign(this.originalState, this.item);
  },
  computed: {
    ...mapGetters("stores", ["getStores"]),

    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        id: this.idParam,
        stores: this.selectedStores,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
