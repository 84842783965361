import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":""}},[_c('toolbar',{attrs:{"title":_vm.$t('user.ac')}},[_c(VBtn,{staticClass:"mx-2",attrs:{"small":"","text":"","color":"success"},on:{"click":_vm.save}},[_c(VIcon,[_vm._v("mdi-content-save")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"overflow-y-auto px-0 py-0",style:(_vm.getViewHeightStyle(84))},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.getStores),function(store,i){return _c('div',{key:i},[(i !== 0)?_c(VDivider):_vm._e(),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(store.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(store.description)+" ")])],1),_c(VListItemAction,[_c(VSwitch,{attrs:{"value":store.id},model:{value:(_vm.selectedStores),callback:function ($$v) {_vm.selectedStores=$$v},expression:"selectedStores"}})],1)],1)],1)}),0)],1),_c('form-bar',{attrs:{"disable-validation":""},on:{"save":_vm.save,"reset":_vm.reset}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }