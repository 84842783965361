import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c('toolbar',{attrs:{"title":_vm.$t('user.ac')}},[_c(VBtn,{staticClass:"mx-2",attrs:{"small":"","text":"","color":"success"},on:{"click":_vm.save}},[_c(VIcon,[_vm._v("mdi-content-save")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1),_c(VCardText,{staticClass:"overflow-y-auto px-0 py-0",style:(_vm.getViewHeightStyle(104))},[_c(VForm,{ref:"form",staticClass:"py-5 px-5"},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.getRoles),function(r,i){return _c('div',{key:i},[(i !== 0)?_c(VDivider):_vm._e(),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(r.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(r.description)+" ")])],1),_c(VListItemAction,[_c(VSwitch,{attrs:{"value":r.name},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}})],1)],1)],1)}),0)],1),_c('br'),_c(VDivider),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"success","tile":"","depressed":""},on:{"click":_vm.save}},[_c(VIcon,[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")],1),_c(VBtn,{attrs:{"color":"primary","tile":"","depressed":""},on:{"click":_vm.reset}},[_c(VIcon,[_vm._v("mdi-undo-variant")]),_vm._v(" "+_vm._s(_vm.$t("general.undo"))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }