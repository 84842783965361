<template>
  <full-view :title="getTitle">
    <template v-slot:toolbar>
      <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider vertical />
      <v-btn
        :disabled="isReadOnly || !valid"
        large
        depressed
        color="primary"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="getViewHeightStyle(36)">
        <v-form ref="form" v-model="valid" class="py-5 px-4">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.name"
                    :counter="64"
                    :rules="rules.name"
                    :label="$t('general.name')"
                    required
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.username"
                    :counter="50"
                    :rules="rules.username"
                    :label="$t('user.username')"
                    required
                    :disabled="editMode && !isAdmin"
                    dense
                    autocomplete="off"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="item.typeName"
                    :items="getTypesByGroup('USER_TYPE')"
                    :item-text="getLocale.lang === 'ar' ? 'nameAr' : 'name'"
                    item-value="name"
                    :rules="rules.type"
                    :label="$t('general.type')"
                    required
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.email"
                    :rules="rules.email"
                    :label="$t('user.email')"
                    :disabled="!$hasRole('SUPER-ADMIN') && !$hasRole('ADMIN')"
                    required
                    dense
                    append-icon="mdi-at"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.phone"
                    counter="50"
                    :rules="rules.phone"
                    :label="$t('general.phone')"
                    :disabled="!$hasRole('SUPER-ADMIN') && !$hasRole('ADMIN')"
                    required
                    dense
                    append-icon="mdi-phone"
                  />
                </v-col>
              </v-row>

              <v-row v-if="editMode">
                <v-col>
                  <v-checkbox
                    v-model="item.updatePassword"
                    :label="$t('user.updatePassword')"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-if="item.updatePassword || !editMode"
                    v-model="item.password"
                    :rules="rules.password"
                    :label="$t('user.password')"
                    append-icon="mdi-lock"
                    type="password"
                    required
                    dense
                    autocomplete="off"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="item.updatePassword || !editMode"
                    v-model="item.confirmPassword"
                    :rules="rules.confirm"
                    :label="$t('user.confirmPassword')"
                    append-icon="mdi-lock-check"
                    type="password"
                    required
                    dense
                    autocomplete="off"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="item.enabled"
                    :label="$t('user.active')"
                    dense
                  />
                </v-col>
                <v-col>
                  <v-checkbox
                    v-if="!editMode"
                    v-model="item.skipConfirmation"
                    :label="$t('user.skipConfirmation')"
                    dense
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" v-if="editMode">
              <image-uploader avatar :key-attr="item.uuid" :title="$t('user.avatar')" :edit-mode="editMode" />
            </v-col>
          </v-row>

          <form-bar :valid="valid" @save="save" @reset="reset" @validate="$refs.form.validate()" />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import ImageUploader from "@/common/components/ImageUploader";
import FormBar from "@/common/components/FormBar";

export default {
  name: "UserForm",
  components: { FullView, ImageUploader, FormBar },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => (!!v && v.length >= 2) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        username: [
          (v) => !!v || "Username is required",
          (v) =>
            (!!v && v.length >= 4) || "Username must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 50) ||
            "Name must be less than or eaqual to 50 characters",
          (v) => {
            const re = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Username must start with an alphanumeric character, " +
                "can have a single (- or _) sepcial charactor " +
                "and/or any number of alphanumeric characters within the limit"
            );
          },
        ],
        type: [(v) => !!v || "User type is required"],
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => (!!v && v.length >= 8) || "Not less than 8 numbers",
          (v) => {
            const re = /^[+\-/0-9 ]*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Only numbers, spaces and (+ - or /) sepcial charactors"
            );
          },
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
        ],
        confirm: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.item.password || "Password and confirmation must match",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return !!v && re.test(String(v).toLowerCase());
          },
        ],
      },
    };
  },
  mounted() {
    // Object.assign(this.item, this.data);
    Object.assign(this.originalState, this.item);
    this.loadTypes(true);
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("types", ["getTypesByGroup"]),

    getTitle() {
      if (this.editMode) {
        return this.$t("user.edit");
      } else {
        return this.$t("user.new");
      }
    },
    isReadOnly() {
      return !(
        this.$hasRole("SUPER-ADMIN") ||
        this.$hasRole("ADMIN") ||
        this.isCurrentUser
      );
    },
    isAdmin() {
        return this.$hasRole("SUPER-ADMIN") ||
        this.$hasRole("ADMIN");
    },
    isCurrentUser() {
      const { username } = this.$getUserInfo();
      return username === this.item.username;
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),

    save() {
      this.$emit("save", {
        item: this.item,
        editMode: this.editMode,
        // tempAvatarCode: this.tempAvatarCode,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
