import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5",style:(("border-color: " + (_vm.getColor('darkSecondary')) + ";")),attrs:{"outlined":_vm.compact || _vm.flat,"flat":_vm.compact || _vm.flat}},[_c(VToolbar,{attrs:{"flat":"","short":"","dense":"","height":"40","color":"secondary"}},[(!!_vm.icon)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c(VToolbarTitle,[_c('span',{staticStyle:{"font-size":"12pt","font-weight":"normal","color":"#00000099"}},[_vm._v(_vm._s(_vm.title))])]),_c(VSpacer),_c(VBtn,{staticClass:"ma-0 white",attrs:{"icon":"","small":!_vm.compact,"x-small":_vm.compact},on:{"click":_vm.toggleCollapse}},[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.collapseToggled ? "mdi-chevron-down" : "mdi-chevron-up")+" ")])],1)],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapseToggled),expression:"!collapseToggled"}],class:_vm.compact ? 'pa-0' : 'pa-1'},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }