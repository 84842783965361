import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collapsable-card',{attrs:{"title":_vm.title,"flat":""}},[_c('div',{attrs:{"align":"center"}},[(_vm.avatar)?_c(VAvatar,{staticStyle:{"border":"3px solid #44444430"},attrs:{"size":"126","color":"grey lighten-3"}},[_c('img',{attrs:{"src":!!_vm.tempImage ? _vm.tempImage : _vm.getImage}})]):_vm._e(),(!_vm.avatar)?_c(VCard,{staticStyle:{"overflow":"hidden"},attrs:{"height":"64","color":"grey lighten-3","flat":""}},[_c('img',{attrs:{"height":"64","contain":"","src":!!_vm.tempImage ? _vm.tempImage : _vm.getImage,"onerror":"this.style.display='none'"}})]):_vm._e()],1),_c(VFileInput,{attrs:{"accept":"image/png, image/jpeg, image/bmp","placeholder":"Pick an image","append-icon":"mdi-camera","label":_vm.$t('general.imageFile'),"disabled":!_vm.editMode,"show-size":"","clearable":"","clear-icon":"mdi-image-off"},on:{"change":_vm.imageChanged,"click:clear":_vm.clearImage},model:{value:(_vm.imageModel),callback:function ($$v) {_vm.imageModel=$$v},expression:"imageModel"}}),(!_vm.editMode)?_c(VAlert,{staticClass:"body-2",attrs:{"border":"left","colored-border":"","elevation":"2","type":"info","dense":""}},[_vm._v(" You can update the image after saving the entity ")]):_vm._e(),_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"disabled":!_vm.imageModel && !_vm.editMode,"depressed":""},on:{"click":_vm.uploadImage}},[_c(VIcon,[_vm._v("mdi-progress-upload")]),_vm._v(" Upload ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }