<template>
  <div>
    <br />
    <v-divider />
    <div class="d-flex mx-4">
      <v-btn
        :disabled="!disableValidation && !valid"
        :dark="disableValidation || valid"
        color="success"
        class="mr-4"
        tile
        depressed
        @click="$emit('save')"
      >
        <v-icon>mdi-content-save</v-icon>
        {{ $t("general.save") }}
      </v-btn>

      <v-btn color="primary" tile depressed @click="$emit('reset')">
        <v-icon>mdi-undo-variant</v-icon>
        {{ $t("general.undo") }}
      </v-btn>

      <v-spacer />

      <v-btn v-if="!disableValidation" tile depressed @click="$emit('validate')">
        <v-icon>mdi-check</v-icon>
        {{ $t("general.validate") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormBar",
  props: {
    valid: Boolean,
    disableValidation: Boolean,
  },
};
</script>
